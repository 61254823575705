import React  from "react";


const Footer = props => {
    return (
        <footer className="footer">
            <div className="site-container bg-white">
                <div className="grid-container px-8 py-3 text-center text-sm">
                    <span>Copyright © 2024 / <em>Plage musicale en Bangor ~ Festival de musique et Académie</em> / <em><a href="mailto:plagemusicalebangor@gmail.com">plagemusicalebangor@gmail.com</a></em></span>
                </div>
            </div>
        </footer>
    );
};


export default Footer