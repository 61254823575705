import './Header.scss'

import React, { useState } from 'react'
import { navigate } from '@reach/router'

import { Link } from 'gatsby'

import Logo from '../../assets/images/logo.jpg'

const Category = (props) => {
	const { data, setOpen } = props

	const onInscriptionClick = (url) => {
		setOpen(false)
		navigate(url + '#inscriptions', { state: { inscriptions: true } })
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<li className='relative header__entry'>
			<Link
				to={data.url}
				className={`header__link p-2 text-lg lg:text-base`}
				activeClassName='current'
				onClick={() => setOpen(false)}
				state={{ inscriptions: false }}
			>
				{data.name}
			</Link>
			{data.inscription && (
				<div className='header__wrap'>
					<ul className={`header__submenu space-y-1 rounded-lg`}>
						<li>
							<button
								className={
									'header__link p-1 text-m lg:text-base'
								}
								onClick={() => onInscriptionClick(data.url)}
							>
								Inscriptions
							</button>
						</li>
					</ul>
				</div>
			)}
			{data.childs && (
				<div className='header__wrap'>
					<ul className={`header__submenu space-y-1 rounded-lg`}>
						{data.childs.map((child, index) => (
							<li key={index}>
								<Link
									className={
										'header__link p-1 text-m lg:text-base'
									}
									to={child.url}
									activeClassName='current'
									onClick={() => setOpen(false)}
								>
									{child.name}
								</Link>
							</li>
						))}
					</ul>
				</div>
			)}
		</li>
	)
}

const Menu = (props) => {
	const { setOpen } = props

	const data = require('../../data/pages.json').pages

	return (
		<ul className='drawer space-y-5 xl:space-x-3 lg:space-y-0'>
			{data.map((entry, index) => {
				if (entry.hidden) return null
				return (
					<Category
						key={index}
						data={entry}
						setOpen={setOpen}
					/>
				)
			})}
		</ul>
	)
}

const Burger = (props) => {
	const { open, setOpen } = props

	return (
		<div className='lg:hidden'>
			<button
				className='burger'
				onClick={() => setOpen(!open)}
			>
				<span className='burger__bar' />
				<span className='burger__bar' />
				<span className='burger__bar' />
			</button>
		</div>
	)
}

const Header = () => {
	const [open, setOpen] = useState(false)

	return (
		<header className={`header ${open ? 'active' : ''}`}>
			<div className='site-container'>
				<div className='border-t-4 border-gray-400 lg:rounded-b-lg bg-white lg:shadow-custom'>
					<div className='grid-container py-2'>
						<div className='flex -mx-3 items-center'>
							<div className='px-3 flex-initial'>
								<Link to='/'>
									<img
										className={'header__logo'}
										src={Logo}
										alt={'Plage musicale en Bangor'}
									/>
								</Link>
							</div>
							<div className='px-3 flex-initial'>
								<span className='italic text-darkblue'>
									Plage musicale en Bangor{' '}
									<span className='hidden lg:inline'>
										<br />
										Festival de musique et Académie
									</span>
								</span>
							</div>
							<div className='px-3 flex-auto'>
								<div className='drawer__wrap'>
									<Menu setOpen={setOpen} />
								</div>
								<Burger
									setOpen={setOpen}
									open={open}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
