import "../../assets/scss/app.scss";

import React from "react";
import ContextInstance from '../Utilities/Context';
import CustomTransition from '../Transition/Transition';

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
/*import Callout from "../Callout/Callout";*/



const Layout = ({ children, location }) => {
    return (
        <>
            <ContextInstance.Consumer>
                { context => (
                    <div className="layout">
                        {/*<Callout/>*/}
                        <Header location={ location }/>
                        <main>
                            <CustomTransition location={ location }>
                                {children}
                            </CustomTransition>
                        </main>
                        <Footer/>
                    </div>
                )}
            </ContextInstance.Consumer>
        </>
    );
}

export default Layout;