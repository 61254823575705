import React from "react"

const defaultState = {
    isSwitcher: true,
    toggleSwitcher: () => {},
}

const ContextInstance = React.createContext(defaultState)


class ContextProvider extends React.Component {
    state = {
        isSwitcher: true,
        activeConseil: null,
        initialSlide: 0
    }

    toggleConseil = (value) => { this.setState({ activeConseil: value }) }

    toggleSwitcher = (value) => { this.setState({ isSwitcher: value }) }

    setInitialSlide = (value) => { this.setState({ initialSlide: value }) }

    render() {
        const { children } = this.props
        const { isSwitcher, activeConseil, initialSlide } = this.state

        return (
            <ContextInstance.Provider
                value={{
                    isSwitcher,
                    activeConseil,
                    initialSlide,
                    toggleSwitcher: this.toggleSwitcher,
                    toggleConseil: this.toggleConseil,
                    setInitialSlide: this.setInitialSlide
                }}
            >
                { children }
            </ContextInstance.Provider>
        )
    }
}

export default ContextInstance

export { ContextProvider }