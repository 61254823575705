import React from "react"
import { TransitionGroup, Transition as ReactTransition } from "react-transition-group"


const timeout = 600
const getTransitionStyles = {
    entering: { opacity: 0, },
    entered: { transition: `opacity ${timeout}ms ease-in-out`, opacity: 1, },
    exiting: { transition: `opacity ${timeout}ms ease-in-out`, opacity: 0, },
}

class CustomTransition extends React.PureComponent {
    render() {
        const { children, location } = this.props
        return (
            <TransitionGroup>
                <ReactTransition key={ location.pathname } timeout={{ enter: 0, exit: 0, }}>
                    {status => (
                        <div style={{ ...getTransitionStyles[status], }}>
                            { children }
                        </div>
                    )}
                </ReactTransition>
            </TransitionGroup>
        )
    }
}

export default CustomTransition