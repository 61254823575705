/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


import "./src/assets/scss/app.scss"

import React from "react"
import { ContextProvider } from "./src/components/Utilities/Context";


export const wrapRootElement = ({ element }) => (
    <ContextProvider>{ element }</ContextProvider>
)